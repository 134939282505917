/** @module utils/save_tracking_infos UTMパラメーターなどのトラッキング情報をCookieに保存するスクリプト */
import Cookies from "js-cookie";

// 除外するURI
var noneTargetURIPatterns = 
  new Array("/consultant/",
            "/consultantHelp/",
            "/sysadmin/",
            "/healthCheck",
            "/fckeditor/",
            "/api/",
            "/api-open/",
            "/popup/",
            "/lp_m/",
            "/viewed");
// m3careerドメイン
var defaultM3Domain = ".m3career.com";

var defaultCookiePath = "/";
var defaultCookieExpires = 183;  // 日数
var defaultCookieSecure = false;

function saveLandingInfos(){
  var domain = getCookieDomain();
  var path = defaultCookiePath;
  var expires = defaultCookieExpires;
  var secure = defaultCookieSecure;
  
  var siteDomainName = location.hostname;
  
  var referrer = document.referrer;
  var referrerHostName = null;
  
  var requestHostName = location.href.split('/')[2];
  var requestUri = location.href.substring(location.href.indexOf(requestHostName) + requestHostName.length);
  
  if(referrer) {
    referrerHostName = referrer.split('/')[2];
  }
  
  // 他ドメインからの場合、流入元として記録する。
  if( isTargetRequest(referrer, referrerHostName, requestUri, siteDomainName)){
    setTrackingCookie('landed_page_referer', referrer, domain, path, expires, secure);
    setTrackingCookie('landed_page_uri', requestUri, domain, path, expires, secure);
    saveUtms(true);
  } else {
    saveUtms(false);
  }

  if(!Cookies.get('uuid')) {
    // uuid未設定の場合は設定
    Cookies.set('uuid', guid());
  }
}

function isTargetRequest(referrer, referrerHostName, requestUri, siteDomainName){
  // referrerあり
  if(referrer == null || referrer.length == 0 || referrer == '-'){
    return false;
  } 
  
  // referrerが他ドメイン
  if(referrerHostName == null || referrerHostName.length == 0 || referrerHostName.indexOf(siteDomainName) == 0){
    return false;
  } 
    
  //除外URIリストに含まれない
  var i = 0;
  if(requestUri == null || requestUri.length == 0){
    return true;
  }
  
  if(siteDomainName == "agent.m3career.com" ){
    for(i = 0; i < noneTargetURIPatterns.length; i++){
      // 除外URIと前方一致すれば、対象外とする。
      if (requestUri.indexOf(noneTargetURIPatterns[i]) == 0){
        return false;
      }
    }
  }

  return true;
}

// overwriteAnywise: utm_sourceがなくても強制的に上書きする
function saveUtms(overwriteAnywise){
  var utmParamNames = new Array("utm_source","utm_medium","utm_content","utm_campaign");
  var gaParamNames = new Array("ga_source","ga_medium","ga_content","ga_campaign");
  var domain = getCookieDomain();
  var path = defaultCookiePath;
  var expires = defaultCookieExpires;
  var secure = defaultCookieSecure;

  var queryParams = getUrlVars();
  
  var i = 0;
  
  if(hasParameter(queryParams, utmParamNames)){
    for(i = 0; i < utmParamNames.length; i++){
      var val = utmParamNames[i];
      const utmValue = queryParams[val];
      setTrackingCookie(val, utmValue, domain, path, expires, secure);
    }
  } else if(hasParameter(queryParams, gaParamNames)){
    for(i = 0; i < gaParamNames.length; i++){
      var val = gaParamNames[i];
      const utmValue = queryParams[val];
      // クッキー名はutm_付き
      setTrackingCookie(utmParamNames[i], utmValue, domain, path, expires, secure);
    }
  } else if(overwriteAnywise){ 
    // 強制上書きの場合、utm_sourceがなければ、クッキーからutmを削除する。
    for(i = 0; i < utmParamNames.length; i++){
      setTrackingCookie(utmParamNames[i], null, domain, path, expires, secure);
    }
  }
}

function hasParameter(queryParams, paramNames){
  for(let i = 0; i < paramNames.length; i++){
    if (queryParams[paramNames[i]] != null && queryParams[paramNames[i]].length > 0){
      return true;
    }
  }
  return false;
}

function getCookieDomain(){
  // m3generatorは.m3.comドメインのクッキーしかアクセスできないため、各サブドメインのクッキーを.m3.comで作成する。
  if (location.hostname.indexOf(defaultM3Domain) > -1){
    return defaultM3Domain;
  } else {
    return null;
  }
}

function getUrlVars()
{
    var vars = [], hash;
    var pageUrl = window.location.href;
    
    if(pageUrl.indexOf('?') > 0){
      var urlQuery = pageUrl.slice(pageUrl.indexOf('?') + 1);
      // アンカー(#以降)を除く
      if(urlQuery.indexOf('#') > 0){
        urlQuery = urlQuery.slice(0, urlQuery.indexOf('#'));
      }
      var hashes = urlQuery.split('&'); 
      for(var i = 0; i < hashes.length; i++) { 
          hash = hashes[i].split('='); 
          vars.push(hash[0]); 
          vars[hash[0]] = hash[1]; 
      } 
    }
    return vars;
} 


//クッキー保存　setTrackingCookie(クッキー名, クッキーの値, クッキーの有効日数); //
function setTrackingCookie(name, value, domain, path, expires, secure) {
  if (!name) return;
  
  // 値がnullの場合はcookieを削除する。
  if (value == null) expires = -1;

  var str = name + "=" + escape(value);
  if (domain) {
    if (domain == 1) domain = location.hostname.replace(/^[^\.]*/, "");
    str += "; domain=" + domain;
  }
  if (path) {
    if (path == 1) path = location.pathname;
    str += "; path=" + path;
  }
  if (expires) {
    var nowtime = new Date().getTime();
    expires = new Date(nowtime + (60 * 60 * 24 * 1000 * expires));
    expires = expires.toGMTString();
    str += "; expires=" + expires;
  }
  if (secure && location.protocol == "https:") {
    str += "; secure";
  }

  document.cookie = str;
}

function S4() {
  return Math.ceil(((1+Math.random())*0x10000)).toString(16).substring(1);
};

/** guidを生成 */
function guid() {
  return (S4()+S4()+'-'+S4()+'-'+S4()+'-'+S4()+'-'+S4()+S4()+S4());
};

// トラッキング情報をCookieに保存
saveLandingInfos();
